import React from 'react'
import HomeSlider from '../../Components/HomeSlider/HomeSlider';
import './Home.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AboutImg from "../../Components/Assets/about-img.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import SliderOne from "../../Components/Assets/Enscape_04.jpg";
import SliderTwo from "../../Components/Assets/Enscape_05.jpg";
import SliderThree from "../../Components/Assets/Enscape_06.jpg";
import SliderFour from "../../Components/Assets/Enscape_07.jpg";
import SliderFive from "../../Components/Assets/Enscape_08.jpg";
import SliderSix from "../../Components/Assets/Enscape_09.jpg";
import Button from 'react-bootstrap/Button';



const Home = () => {
  return (
    <React.Fragment>
    <HomeSlider/>
    <Container fluid className="px-4 px-lg-5 mb-lg-5 mb-sm-0 ">
    <Row className="justify-content-md-center d-flex align-items-center">
        <Col md lg="4">
        <img src={AboutImg} alt="" className="d-block w-100" />
        </Col>
        <Col md lg="5" className=''>
        <div className='px-md-5 px-sm-2'>
        <h1>About</h1>
       <div className='quote'>
       <h5 className='ms-5 px-5 '>interior designer</h5>
        </div> 
              <p className='mt-4'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur modi recusandae dolorum consequuntur explicabo. Nobis dolores laboriosam, culpa quae, magnam qui a expedita cumque unde omnis incidunt debitis perspiciatis eligendi id. Aliquid aperiam, tempore laborum eius explicabo totam voluptatibus enim maiores soluta ullam ipsa animi quidem aspernatur adipisci sed reiciendis perferendis!
            </p>
        </div>

        </Col>

       
      </Row>
    </Container>

    <Container fluid className="px-4 px-lg-5 mb-lg-5  mb-sm-0 px-sm-0  ">

    <Row>
      <Col lg="12" className='text-center py-3 py-sm-0'>
        <h1>Portfolio</h1>
      </Col>
    </Row>

    <Row className="justify-content-md-center">
    <Swiper
     modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      centeredSlides={false}
    //  slidesPerView={'auto'}
    breakpoints={{
      576: {
        // width: 576,
        slidesPerView: 1,
      },
      768: {
        // width: 768,
        slidesPerView: 2,
      },
      1024: {
        // width: 768,
        slidesPerView: 3,
      },
    }}
  
      loop={true}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
    >
      <SwiperSlide>
        <img src={SliderOne} alt="" className='img-fluid' />
  <div className="position-relative">
  <div className="position-absolute bottom-0 start-0 bg-dark bg-secondary w-100 bg-opacity-50">
      <div className="d-flex justify-content-between ps-3 pe-3 d-flex align-items-center">
    <div className='text-white text-start pt-3'>
      <h5 className='mb-0'>Project Name</h5>
      <p>Location</p>
      </div>
    <div>
    <Button variant="light">Read More</Button>
    </div>
    </div>
  </div>
</div>

      </SwiperSlide>

      <SwiperSlide>
        <img src={SliderOne} alt="" className='img-fluid' />
        <div className="position-relative">
  <div className="position-absolute bottom-0 start-0 bg-dark bg-secondary w-100 bg-opacity-50">
      <div className="d-flex justify-content-between ps-3 pe-3 d-flex align-items-center">
    <div className='text-white text-start pt-3'>
    <h5 className='mb-0'>Project Name</h5>
      <p>Location</p>
      </div>
    <div>
    <Button variant="light">Read More</Button>
    </div>
    </div>
  </div>
</div>
      </SwiperSlide>

      <SwiperSlide>
        <img src={SliderTwo} alt="" className='img-fluid' />
        <div className="position-relative">
  <div className="position-absolute bottom-0 start-0 bg-dark bg-secondary w-100 bg-opacity-50">
      <div className="d-flex justify-content-between ps-3 pe-3 d-flex align-items-center">
    <div className='text-white text-start pt-3'>
    <h5 className='mb-0'>Project Name</h5>
      <p>Location</p>
      </div>
    <div>
    <Button variant="light">Read More</Button>
    </div>
    </div>
  </div>
</div>
      </SwiperSlide>

      <SwiperSlide>
        <img src={SliderThree} alt="" className='img-fluid' />
        <div className="position-relative">
  <div className="position-absolute bottom-0 start-0 bg-dark bg-secondary w-100 bg-opacity-50">
      <div className="d-flex justify-content-between ps-3 pe-3 d-flex align-items-center">
    <div className='text-white text-start pt-3'>
    <h5 className='mb-0'>Project Name</h5>
      <p>Location</p>
      </div>
    <div>
    <Button variant="light">Read More</Button>
    </div>
    </div>
  </div>
</div>
      </SwiperSlide>

      <SwiperSlide>
        <img src={SliderFour} alt="" className='img-fluid' />
        <div className="position-relative">
  <div className="position-absolute bottom-0 start-0 bg-dark bg-secondary w-100 bg-opacity-50">
      <div className="d-flex justify-content-between ps-3 pe-3 d-flex align-items-center">
    <div className='text-white text-start pt-3'>
    <h5 className='mb-0'>Project Name</h5>
      <p>Location</p>
      </div>
    <div>
    <Button variant="light">Read More</Button>
    </div>
    </div>
  </div>
</div>
      </SwiperSlide>



      <SwiperSlide>
        <img src={SliderFive} alt="" className='img-fluid' />
        <div className="position-relative">
  <div className="position-absolute bottom-0 start-0 bg-dark bg-secondary w-100 bg-opacity-50">
      <div className="d-flex justify-content-between ps-3 pe-3 d-flex align-items-center">
    <div className='text-white text-start pt-3'>
    <h5 className='mb-0'>Project Name</h5>
      <p>Location</p>
      </div>
    <div>
    <Button variant="light">Read More</Button>
    </div>
    </div>
  </div>
</div>
      </SwiperSlide>


      <SwiperSlide>
        <img src={SliderSix} alt="" className='img-fluid' />
        <div className="position-relative">
  <div className="position-absolute bottom-0 start-0 bg-dark bg-secondary w-100 bg-opacity-50">
      <div className="d-flex justify-content-between ps-3 pe-3 d-flex align-items-center">
    <div className='text-white text-start pt-3'>
    <h5 className='mb-0'>Project Name</h5>
      <p>Location</p>
      </div>
    <div>
    <Button variant="light">Read More</Button>
    </div>
    </div>
  </div>
</div>
      </SwiperSlide>





    </Swiper>
    </Row>
    </Container>

    </React.Fragment>
  )
}

export default Home
