import React from 'react'
import './PortfolioImg.css'

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';



const PortfolioImg = (props) => {

  return (

    <React.Fragment>
<a href={props.image} className='vid-list' title={props.name}  >
<img src={props.image} className="thumbnail" alt="img01" />

<div className="flex-div">
    <div className="vid-info">
    <p className='underline'>{props.name}</p>
    {/* <p>{props.details}</p> */}
    </div></div>

</a>






</React.Fragment>



 
  )
}

export default PortfolioImg
