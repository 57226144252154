import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import { PiUserCircleFill } from "react-icons/pi";
import { GiPhone } from "react-icons/gi";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./Contact.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <React.Fragment>
      <Container fluid className="px-4 px-lg-5 mb-5">
        <Row className="justify-content-md-center d-flex ">
          <Col lg="12" className="mb-4">
            <div className="border-top border-bottom pt-4 pb-4 px-1 ">
           <div className="d-flex justify-content-between">
           <div> <h3 className="fw-500">Contact</h3></div>
            <div> 
            <Breadcrumb>
      <Breadcrumb.Item><Link as={Link}to="/"> <FaHome /></Link> </Breadcrumb.Item>
       <Breadcrumb.Item active>Contact</Breadcrumb.Item>
    </Breadcrumb>

            </div>


           </div>



            </div>



          </Col>

          <Col lg="4" className="">
            <div className="letterS">
              <h1 className="text-uppercase mb-4">Let's Talk</h1>
            </div>

            <h3>info@dishadua.in</h3>
            <div className="mt-3">
              <p>
                <PiUserCircleFill />{" "}
                <span className="ms-2">Mrs. Disha Dua</span>
              </p>
              <p>
                <GiPhone /> <span className="ms-2">+91 123456978</span>
              </p>
              <p>
                Join Us On Our Journey
                <br />
                <FaInstagram /> <FaFacebookF className="ms-2" />
              </p>
            </div>
          </Col>

          <Col lg="4" className="">
            <div className="letterS mb-4">
              <h1 className="text-uppercase mt-0">Write to Us</h1>
            </div>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control type="text" placeholder="Your Name" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control type="email" placeholder="Enter email" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="4"
                  placeholder="Message"
                ></textarea>
              </Form.Group>

              <div className="d-grid">
                <Button variant="dark" type="submit" size="lg">
                  Send Message
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>




      <Container fluid className="px-4 px-lg-5 mb-5">
      
    <Row>
    <Col lg="12">

    <iframe id="map" title="myFrame" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15092.520392563127!2d72.8032996114464!3d18.969860957815566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ce7393614147%3A0x98c343b8067470c9!2sTardeo%2C%20Mumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1702101187424!5m2!1sen!2sin" width="100%" height="450"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

    </Col>

    </Row>

    </Container>





    </React.Fragment>
  );
};

export default Contact;
