import React from 'react'
import './ThirdYrPhotosGallery.css'
import third_year from '../Assets/ThirdYearPortfolio'

import Col from "react-bootstrap/Col";
import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgShare from 'lightgallery/plugins/share';


import PortfolioImg from '../PortfolioImg/PortfolioImg';




const ThirdYrPhotosGallery = () => {
  const onInit = () => {
    console.log('lightGallery has been initialized');
};
  return (
    <React.Fragment>
    <Col lg="12">

    <LightGallery
           onInit={onInit}
         
           speed={500}
           plugins={[lgThumbnail, lgZoom, lgAutoplay, lgShare]}
           elementClassNames='list-container'
           licenseKey= "my license"
       >

       { third_year.map((portfolio, i)=>{
                        return <PortfolioImg key={i} id={portfolio.id} name={portfolio.name} image={portfolio.image} />

       })}
     
       </LightGallery>
     
      
   </Col>
   
</React.Fragment>
  )
}

export default ThirdYrPhotosGallery
