import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <React.Fragment>
      <Container fluid className="px-4 px-lg-5 ">
        <Row className=" ">
          <Col lg="12" className=" mb-lg-3 bg-light p-4 mb-sm-0">
            <div className="d-flex flex-wrap flex-column flex-md-row justify-content-center align-items-md-center">
              <div className="me-4 pt-lg-4  border-end pt-sm-0 pb-sm-0">
                <h1 className="logo-text text-uppercase mt-4 mt-sm-0 mb-4 pe-4">DISHA DUA</h1>
              </div>

              <div className="me-4">
              <p className=" p-0 mb-0">
                  Add: Churchgate Station Underpass, Churchgate, Mumbai,
                  Maharashtra 400020
                </p>
                <p className=" p-0 mb-0">Tel: +91 123456789</p>
                
                <p className="p-0 mb-0">Email: <a href="mailto:info@dishadua.in" className="text-dark">info@dishadua.in</a></p>
              </div>

              <div className="footer-links">
                <ul>
                <li><NavLink to="/About">About</NavLink></li>
                  <li><NavLink to="/Portfolio" >Portfolio</NavLink></li>
                             <li><NavLink to="/Contact" >Contact</NavLink></li>
                </ul>

              



              </div>
            </div>
          </Col>

          <Col lg="12">
            <div className="text-center small pb-1 mt-0 pt-0">
              Copyright &copy; 2023 Disha Dua | All Right Reserved. | Designed By <a rel="noreferrer" href="https://www.goradiainfotech.com/" target="_blank" className="text-dark">Goradia Infotech</a>


            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Footer;
