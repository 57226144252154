import sy01_large from './second-yr/Enscape_01.jpg'
import sy02_large from './second-yr/Enscape_02.jpg'
import sy03_large from './second-yr/Enscape_03.jpg'
import sy04_large from './second-yr/Enscape_04.jpg'
import sy05_large from './second-yr/Enscape_05.jpg'
import sy06_large from './second-yr/Enscape_06.jpg'
import sy07_large from './second-yr/Enscape_07.jpg'
import sy08_large from './second-yr/Enscape_08.jpg'
import sy09_large from './second-yr/Enscape_09.jpg'
import sy10_large from './second-yr/Enscape_10.jpg'
import sy11_large from './second-yr/Enscape_11.jpg'
import sy12_large from './second-yr/Enscape_12.jpg'
import sy13_large from './second-yr/Enscape_13.jpg'
import sy14_large from './second-yr/Enscape_14.jpg'
import sy15_large from './second-yr/Enscape_15.jpg'
import sy16_large from './second-yr/Enscape_16.jpg'
import sy17_large from './second-yr/Enscape_17.jpg'
import sy18_large from './second-yr/Enscape_18.jpg'
import sy19_large from './second-yr/Enscape_19.jpg'
import sy20_large from './second-yr/Enscape_20.jpg'
import sy21_large from './second-yr/Enscape_21.jpg'
import sy22_large from './second-yr/Enscape_22.jpg'
//import sy23_large from './second-yr/Enscape_23.jpg'
//import sy24_large from './second-yr/Enscape_24.jpg'
import sy25_large from './second-yr/Enscape_25.jpg'
import sy26_large from './second-yr/Enscape_26.jpg'
import sy27_large from './second-yr/Enscape_27.jpg'
//import sy28_large from './second-yr/Enscape_28.jpg'
import sy29_large from './second-yr/Enscape_29.jpg'
import sy30_large from './second-yr/Enscape_30.jpg'
import sy31_large from './second-yr/Enscape_31.jpg'
import sy32_large from './second-yr/Enscape_32.jpg'
import sy33_large from './second-yr/Enscape_33.jpg'
import sy34_large from './second-yr/Enscape_34.jpg'
import sy35_large from './second-yr/Enscape_35.jpg'


import sy36_large from './second-yr/Professor-Moon.jpg'
import sy37_large from './second-yr/studio-overview.jpg'
import sy38_large from './second-yr/Apartment-Design.jpg'
import sy39_large from './second-yr/Exploded-Axonometric.jpg'
import sy40_large from './second-yr/Floor-Plans.jpg'

import sy41_large from './second-yr/Mirrored-Plan.jpg'
import sy42_large from './second-yr/Sections.jpg'
import sy43_large from './second-yr/Renderings-01.jpg'
import sy44_large from './second-yr/Renderings-02.jpg'
import sy45_large from './second-yr/Renderings-03.jpg'
import sy46_large from './second-yr/Renderings-04.jpg'
import sy47_large from './second-yr/Multiple-Dwelling.jpg'
import sy48_large from './second-yr/Massing-Diagram.jpg'
import sy49_large from './second-yr/Site-Plan.jpg'
import sy50_large from './second-yr/Floor-Plans-02.jpg'

import sy51_large from './second-yr/Unit-Mix-Diagram.jpg'
import sy52_large from './second-yr/Perspective-Section.jpg'
import sy53_large from './second-yr/Renderings-05.jpg'
import sy54_large from './second-yr/Renderings-06.jpg'
import sy55_large from './second-yr/Renderings-07.jpg'
import sy56_large from './second-yr/Renderings-08.jpg'
import sy57_large from './second-yr/Renderings-09.jpg'
import sy58_large from './second-yr/Renderings-10.jpg'
import sy59_large from './second-yr/Renderings-11.jpg'
import sy60_large from './second-yr/Renderings-12.jpg'

import sy61_large from './second-yr/Multiple-Family-Housing.jpg'
import sy62_large from './second-yr/Site-Analysis.jpg'
import sy63_large from './second-yr/Site-Analysis-02.jpg'
import sy64_large from './second-yr/Site-Analysis-03.jpg'
import sy65_large from './second-yr/Massing-Diagram-01.jpg'
import sy66_large from './second-yr/Massing-Diagram-02.jpg'
import sy67_large from './second-yr/Massing-Diagram-03.jpg'
import sy68_large from './second-yr/Massing-Diagram-04.jpg'
import sy69_large from './second-yr/Massing-Diagram-05.jpg'
import sy70_large from './second-yr/Massing-Diagram-06.jpg'

import sy71_large from './second-yr/Unit-Mix-Diagram-B.jpg'
import sy72_large from './second-yr/Site-Plan-03.jpg'
import sy73_large from './second-yr/Floor-Plans-3A.jpg'
import sy74_large from './second-yr/Floor-Plans-3B.jpg'
import sy75_large from './second-yr/Floor-Plans-3C.jpg'
import sy76_large from './second-yr/Floor-Plans-3-Seven.jpg'
import sy77_large from './second-yr/Sections-3A.jpg'
import sy78_large from './second-yr/Sections-3B.jpg'
import sy79_large from './second-yr/Physical-Models.jpg'
import sy80_large from './second-yr/Physical-Models-3B.jpg'

import sy81_large from './second-yr/Renderings3-A.jpg'
import sy82_large from './second-yr/Renderings3-B.jpg'
import sy83_large from './second-yr/Renderings3-C.jpg'
import sy84_large from './second-yr/Renderings3-D.jpg'
import sy85_large from './second-yr/Renderings3-E.jpg'
import sy86_large from './second-yr/Renderings3-F.jpg'
import sy87_large from './second-yr/Renderings3-G.jpg'
import sy88_large from './second-yr/Renderings3-H.jpg'

let third_year = [
  {
    id:1,
    name:"Enscape 01",
    image:sy01_large,
    details:"",
  },
  {id:2,
    name:"Enscape 02",
    image:sy02_large,
    details:"",
  },
  {id:3,
    name:"Enscape 03",
    image:sy03_large,
    details:"",
  },
  {id:4,
    name:"Enscape 04",
    image:sy04_large,
    details:"",
  },
  {id:5,
    name:"Enscape 05",
        image:sy05_large,
        details:"",
      },
  {id:6,
    name:"Enscape 06",
        image:sy06_large,
        details:"",
      },
  {id:7,
    name:"Enscape 07",
    image:sy07_large,
    details:"",
  },
  {id:8,
    name:"Enscape 08",
    image:sy08_large,
    details:"",
  },
  {id:9,
    name:"Enscape 09",
    image:sy09_large,
    details:"",
  },
  {id:10,
    name:"Enscape 10",
    image:sy10_large,
    details:"",
  },
  {id:11,
    name:"Enscape 11",
    image:sy11_large,
    details:"",
  },
  {id:12,
    name:"Enscape 12",
    image:sy12_large,
    details:"",
  },
  {id:13,
    name:"Enscape 13",
    image:sy13_large,
    details:"",
  },
  {id:14,
    name:"Enscape 14",
    image:sy14_large,
    details:"",
  },
  {id:15,
    name:"Exploded Axonometric",
    image:sy15_large,
    details:"",
  },
  {id:16,
    name:"Isometric Section",
    image:sy16_large,
    details:"",
  },
  {id:17,
    name:"Massing Study 01",
    image:sy17_large,
    details:"",
  },
  {id:18,
    name:"Massing Study 02",
    image:sy18_large,
    details:"",
  },
  {id:19,
    name:"Massing Study 03",
    image:sy19_large,
    details:"",
  },
  {id:20,
    name:"Massing Study 04",
    image:sy20_large,
    details:"",
  },
  {id:21,
    name:"Greenery Diagram",
    image:sy21_large,
    details:"",
  },
  {id:22,
    name:"Ground Floor Plan",
    image:sy22_large,
    details:"",
  },
  // {id:23,
  //   name:"Sections And Sectional Sequence",
  //   image:sy23_large,
  //       details:"",
  // },

  // {id:24,
  //   name:"Sections And Sectional Sequence",
  //   image:sy24_large,
  //       details:"",
  // },
  {id:25,
    name:"Project - IMG_5076",
    image:sy25_large,
    details:"",
  },
  {id:26,
    name:"Project - IMG_5083",
    image:sy26_large,
    details:"",
  },
  {id:27,
    name:"Project - IMG_5110",
    image:sy27_large,
    details:"",
  },

  // {id:28,
  //   name:"Sections And Sectional Sequence",
  //   image:sy28_large,
  //       details:"",
  // },
  {id:29,
    name:"Mirror Elevation",
    image:sy29_large,
    details:"",
  },
  {id:30,
    name:"Mirrored Plan",
    image:sy30_large,
    details:"",
  },
  {id:31,
    name:"Third Floor Plan @ 1/16",
    image:sy31_large,
    details:"",
  },
  {id:32,
    name:"Fourth Floor Plan @ 1/16",
    image:sy32_large,
    details:"",
  },
  {id:33,
    name:"Sixth Floor Plan @ 1/16",
    image:sy33_large,
    details:"",
  },
  {id:34,
    name:"Sectional Diagram [Converted]",
    image:sy34_large,
    details:"",
  },
  {id:35,
    name:"Sections",
    image:sy35_large,
    details:"",
  },
  {id:36,
    name:"Professor Moon - ARCH 301",
    image:sy36_large,
    details:"",
  },
  {id:37,
    name:"Studio Overview",
    image:sy37_large,
    details:"",
  },
  {id:38,
    name:"Apartment Design",
    image:sy38_large,
    details:"",
  },
  {id:39,
    name:"Exploded Axonometric",
    image:sy39_large,
    details:"",
  },
  {id:40,
    name:"Floor Plans",
    image:sy40_large,
    details:"",
  }, 
   {id:41,
    name:"Mirrored Plan",
    image:sy41_large,
    details:"",
  },
  {id:42,
    name:"Sections",
    image:sy42_large,
    details:"",
  },
  {id:43,
    name:"Renderings 01",
    image:sy43_large,
    details:"",
  },
  {id:44,
    name:"Renderings 02",
    image:sy44_large,
    details:"",
  },
  {id:45,
    name:"Renderings 03",
    image:sy45_large,
    details:"",
  },
  {id:46,
    name:"Renderings 04",
    image:sy46_large,
    details:"",
  },
  {id:47,
    name:"Multiple Dwelling",
    image:sy47_large,
    details:"",
  },
  {id:48,
    name:"Massing Diagram",
    image:sy48_large,
        details:"",
  },
  {id:49,
    name:"Site Plan",
    image:sy49_large,
        details:"",
  },
  {id:50,
    name:"Floor Plans",
    image:sy50_large,
        details:"",
  },
  {id:51,
    name:"Unit Mix Diagram",
    image:sy51_large,
        details:"",
  },
  {id:52,
    name:"Perspective Section",
    image:sy52_large,
        details:"",
  },
  {id:53,
    name:"Renderings 05",
    image:sy53_large,
        details:"",
  },
  {id:54,
    name:"Renderings 06",
    image:sy54_large,
        details:"",
  },
  {id:55,
    name:"Renderings 07",
    image:sy55_large,
        details:"",
  },
  {id:56,
    name:"Renderings 08",
    image:sy56_large,
        details:"",
  },
  {id:57,
    name:"Renderings 09",
    image:sy57_large,
        details:"",
  },
  {id:58,
    name:"Renderings 10",
    image:sy58_large,
        details:"",
  },
  {id:59,
    name:"Renderings 11",
    image:sy59_large,
        details:"",
  },
  {id:60,
    name:"Renderings 12",
    image:sy60_large,
        details:"",
  },
  {id:61,
    name:"Multiple Family Housing",
    image:sy61_large,
        details:"",
  },
  {id:62,
    name:"Site Analysis 01",
    image:sy62_large,
        details:"",
  },

  {id:63,
    name:"Site Analysis 02",
    image:sy63_large,
        details:"",
  },
  {id:64,
    name:"Site Analysis 03",
    image:sy64_large,
        details:"",
  },

  {id:65,
    name:"Massing Diagram 01",
    image:sy65_large,
        details:"",
  },
  {id:66,
    name:"Massing Diagram 02",
    image:sy66_large,
        details:"",
  },
  {id:67,
    name:"Massing Diagram 03",
    image:sy67_large,
        details:"",
  },
  {id:68,
    name:"Massing Diagram 04",
    image:sy68_large,
        details:"",
  },
  {id:69,
    name:"Massing Diagram 05",
    image:sy69_large,
        details:"",
  },
  {id:70,
    name:"Project - Image",
    image:sy70_large,
        details:"",
  },
  {id:71,
    name:"Unit Mix Diagram",
    image:sy71_large,
        details:"",
  },
  {id:72,
    name:"Site Plan",
    image:sy72_large,
        details:"",
  },
  {id:73,
    name:"Floor Plans - Third Floor @ 1/16",
    image:sy73_large,
        details:"",
  },
  {id:74,
    name:"Floor Plans - Fourth Floor @ 1/16",
    image:sy74_large,
        details:"",
  },
  {id:75,
    name:"Floor Plans - Sixth Floor @ 1/16",
    image:sy75_large,
        details:"",
  },
  {id:76,
    name:"Floor Plans - Seventh Floor @ 1/16",
    image:sy76_large,
        details:"",
  },
  {id:77,
    name:"Sections 01",
    image:sy77_large,
        details:"",
  },
  {id:78,
    name:"Sections 02",
    image:sy78_large,
        details:"",
  },
  {id:79,
    name:"Physical Models 01",
    image:sy79_large,
        details:"",
  },
  {id:80,
    name:"Physical Models 02",
    image:sy80_large,
        details:"",
  },
  {id:81,
    name:"Renderings 01",
    image:sy81_large,
        details:"",
  },
  {id:82,
    name:"Renderings 02",
    image:sy82_large,
        details:"",
  },
  {id:83,
    name:"Renderings 03",
    image:sy83_large,
        details:"",
  },
  {id:84,
    name:"Renderings 04",
    image:sy84_large,
        details:"",
  },
  {id:85,
    name:"Renderings 05",
    image:sy85_large,
        details:"",
  },
  {id:86,
    name:"Renderings 06",
    image:sy86_large,
        details:"",
  },
  {id:87,
    name:"Renderings 07",
    image:sy87_large,
        details:"",
  },
  {id:88,
    name:"Renderings 08",
    image:sy88_large,
        details:"",
  },
  







];

export default third_year;
