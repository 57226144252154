import React from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Portfolio.css";
import { Link } from "react-router-dom";
import PortfolioItem from "../../Components/PortfolioItem/PortfolioItem";

const FirstYear2021 = () => {
  return (
    <React.Fragment>
      <Container fluid className="px-4 px-lg-5 ">
        <Row className="justify-content-md-center  ">
          <Col lg="12" className="mb-4">
            <div className="border-top border-bottom pt-4 pb-4 px-1 ">
              <div className="d-flex  flex-column flex-md-row justify-content-between">
                <div>
                  
                  <h3 className="fw-500">Portfolio - First Year 2021</h3>
                </div>

                <div>
                <Link to="/Portfolio/ThirdYear2023" className='text-dark me-3'>Third Year 2023</Link>
        <Link to="/Portfolio/SecondYear2022" className='text-dark me-3'>Second Year 2022</Link>
        <Link to="/Portfolio/FirstYear2021" className='btn btn-dark'>First Year 2021</Link> 
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="px-4 px-lg-5">
        <Row >
   
        <PortfolioItem/>          
           


       
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default FirstYear2021
