import fy_img01 from './first-yr/Volumetric-Vessels.jpg'
import fy_img02 from './first-yr/Painting-Layer-Analysis.jpg'
import fy_img03 from './first-yr/Taxonomy-Grid---3d-Vessel-Conversion.jpg'
import fy_img04 from './first-yr/Sectional-Analysis.jpg'
import fy_img05 from './first-yr/Sectional-Analysis-02.jpg'
import fy_img06 from './first-yr/Sections-And-Sectional-Sequence.jpg'
import fy_img07 from './first-yr/Sectional-Analysis-Sequence.jpg'
import fy_img08 from './first-yr/Interior-Perspectives.jpg'
import fy_img09 from './first-yr/Sectional-Narrative.jpg'
import fy_img10 from './first-yr/Sections-Plans.jpg'
import fy_img11 from './first-yr/Interior-Perspectives-2.jpg'
import fy_img12 from './first-yr/Interior-Renderings--Light-Study.jpg'
import fy_img13 from './first-yr/Interior-Renderings--Light-Study-2.jpg'
import fy_img14 from './first-yr/Visualization-II.jpg'
import fy_img15 from './first-yr/Voronoi-Pattern-Site-Analysis.jpg'
import fy_img16 from './first-yr/Enclosure-Pattern-Paneling-01.jpg'
import fy_img17 from './first-yr/2D-Perspective-Sections.jpg'


let data_product = [
  {
    id:1,
    name:"Volumetric Vessels- Project 01",
    image:fy_img01,
    details:"",
  },
  {id:2,
    name:"Painting Layer Analysis",
    image:fy_img02,
    details:"",
  },
  {id:3,
    name:"Taxonomy Grid - 3d Vessel Conversion",
    image:fy_img03,
    details:"",
  },
  {id:4,
    name:"Sections Analysis & Sectional Sequence",
    image:fy_img04,
    details:"",
  },
  {id:5,
    name:"Sectional Analysis",
    image:fy_img05,
    details:"",
  },
  {id:6,
    name:"Sections And Sectional Sequence",
    image:fy_img06,
    details:"",
  },
  {id:7,
    name:"Sectional Analysis",
    image:fy_img07,
    details:"",
  },
  {id:8,
    name:"Interior Perspectives - Bath House",
    image:fy_img08,
        details:"",
  },
  {id:9,
    name:"Sectional Narrative Bath House... ",
    image:fy_img09,
        details:"",
  },
  {id:10,
    name:"Sections - Plans",
    image:fy_img10,
        details:"",
  },
  {id:11,
    name:"Interior Perspectives - Entry, Cold Bath, Cold Bath Platform, Hot Bath",
    image:fy_img11,
        details:"",
  },
  {id:12,
    name:"Sauna, Tube Passage, Cold Bath Platform",
    image:fy_img12,
        details:"",
  },
  {id:13,
    name:"Cold Bath, Tube Passage",
    image:fy_img13,
        details:"",
  },
  {id:14,
    name:"Visualization II - Plans And Axonometric",
    image:fy_img14,
        details:"",
  },
  {id:15,
    name:"Voronoi Pattern, Site Analysis - Enclosure Pattern Paneling",
    image:fy_img15,
        details:"",
  },
  {id:16,
    name:"Enclosure Pattern Paneling- Plan +Sections",
    image:fy_img16,
        details:"",
  },
  {id:17,
    name:"2d Perspective Sections - 3d Voronoi",
    image:fy_img17,
        details:"",
  },





];

export default data_product;
