//small
import ty01_small from './third-year/Professor-Crystal-Eksi.jpg'
import ty02_small from './third-year/Site-Analysis.jpg'
import ty03_small from './third-year/Precedent-Study.jpg'
import ty04_small from './third-year/Precedent-Study-B.jpg'
import ty05_small from './third-year/Form-Finding.jpg'
import ty06_small from './third-year/Finding-Community.jpg'

import ty07_small from './third-year/Finding-Community-B.jpg'
import ty08_small from './third-year/Finding-Community-C.jpg'

import ty09_small from './third-year/Finding-Community-D.jpg'

import ty10_small from './third-year/Finding-Community-E.jpg'
import ty11_small from './third-year/Finding-Community-F.jpg'

import ty12_small from './third-year/Finding-Community-G.jpg'

import ty13_small from './third-year/Finding-Community-H.jpg'
import ty14_small from './third-year/Finding-Community-I.jpg'
import ty15_small from './third-year/Finding-Community-J.jpg'



let third_year = [
  {
    id:1,
    name:"Professor Crystal Eksi",
    image:ty01_small,
    details:"",

  },
  {id:2,
    name:"Site Analysis",
    image:ty02_small,
    details:"",

  },
  {id:3,
    name:"Precedent Study",
    image:ty03_small,
    details:"Cite De L’ocean Et Du Surf",
  },
  {id:4,
    name:"Precedent Study",
    image:ty04_small,
    details:"Cite De L'ocean Et Du Surf",
  },

  {id:5,
    name:"Form Finding",
    image:ty05_small,
    details:"",
  },
  {id:6,
    name:"Finding Community",
    image:ty06_small,
    details:"",
  },
  {id:7,
    name:"Finding Community",
    image:ty07_small,
    details:"",
  },
  {id:8,
    name:"Finding Community",
    image:ty08_small,
    details:"",
  },
  {id:9,
    name:"Finding Community",
    image:ty09_small,
    details:"",
  },
  {id:10,
    name:"Finding Community",
    image:ty10_small,
    details:"",
  },
  {id:11,
    name:"Finding Community",
    image:ty11_small,
    details:"",
  },
  {id:12,
    name:"Finding Community",
    image:ty12_small,
    details:"View taken from forum looking towards working space",
  },
  {id:13,
    name:"Finding Community",
    image:ty13_small,
    details:"View taken in the theatre looking at the bar.",
  },
  {id:14,
    name:"Finding Community",
    image:ty14_small,
    details:"Exterior view looking at the play pit",
  },
  {id:15,
    name:"Finding Community",
    image:ty15_small,
    details:"Exterior view looking at the theatre and play court.",
  },

];

export default third_year;
