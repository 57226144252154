import React from 'react'

import './AccordianTab.css' 
import '../About/AccordianTab.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AboutImg01 from "../../Components/Assets/about-img-01.png";

const AccordianTab = () => {
  return (
    <React.Fragment>
{/* <Tabs
      defaultActiveKey="price"
      transition={false}
      id="noanim-tab-example"
      className="mb-3 d-flex justify-content-center custom_tab "
    >
      <Tab eventKey="price" title={<span> Site Analysis</span>}>
      <Row className="justify-content-md-center d-flex align-items-center">
      <Col lg="12" className=''>
        <div className=''>
        <Row className="justify-content-center align-items-center">
        <Col md lg="8" className=''>
        <div className='px-5'>
                 <p className='mt-4'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur modi recusandae dolorum consequuntur explicabo. Nobis dolores laboriosam, culpa quae, magnam qui a expedita cumque unde omnis incidunt debitis perspiciatis eligendi id. Aliquid aperiam, tempore laborum eius explicabo totam voluptatibus enim maiores soluta ullam ipsa animi quidem aspernatur adipisci sed reiciendis perferendis!
            </p>
        </div>

        </Col>

        <Col md lg="4">
        <img src={AboutImg} alt="" className="d-block w-100" />
        </Col>





        </Row>





        </div>
      </Col>




       </Row>

      </Tab>

      <Tab eventKey="convenience" title={<span> Precedent Study</span>}>
    
      
      </Tab>


      <Tab eventKey="design"  title={<span> Form Finding</span>}>

      </Tab>

      <Tab eventKey="timelines" title={<span> Community Center</span>}>

    
      </Tab>



    </Tabs> */}

<Row className="justify-content-center align-items-center">

<Col md lg="6" className=''>
<div className=''>
<h5 className='text-start fw-light'>What We Do</h5>
<h2 className='text-start'>Heading</h2>
     <p className=''>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur modi recusandae dolorum consequuntur explicabo. Nobis dolores laboriosam, culpa quae, magnam qui a expedita cumque unde omnis incidunt debitis perspiciatis eligendi id. Aliquid aperiam, tempore laborum eius explicabo totam voluptatibus enim maiores soluta ullam ipsa animi quidem aspernatur adipisci sed reiciendis perferendis!
    </p>
</div>

</Col>

<Col md lg="6">
<img src={AboutImg01} alt="" className="d-block w-100" />
</Col>




</Row>
    </React.Fragment>
  )
}

export default AccordianTab
