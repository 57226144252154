import React from 'react'
import './Header.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaRegEnvelope } from "react-icons/fa";
import { PiPhone } from "react-icons/pi";

const Header = () => {
  return (
    <>
            <Container fluid className='bg-light px-4 px-lg-5'>
        <Row>
          <Col lg="12">
            <div className="d-flex justify-content-end py-0">
            <div className='me-2'>  <a href="/" className='link-secondary link-underline-opacity-0'> <FaRegEnvelope/> info@dishadua.in</a></div>
             <div className='link-secondary'><PiPhone/>+91 9987654321</div>
              </div>
          </Col>

        </Row>
      </Container>
    </>
  )
}

export default Header
