import React from "react";
import Header from "../Header/Header";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./NavbarComp.css";
import Home from '../../Pages/Home/Home'
import About from '../../Pages/About/About'
import Portfolio from '../../Pages/Portfolio/Portfolio'
import Contact from '../../Pages/Contact/Contact'
import Footer from "../Footer/Footer";

import ThirdYear2023 from "../../Pages/Portfolio/ThirdYear2023";
import SecondYear2022 from "../../Pages/Portfolio/SecondYear2022";
import FirstYear2021 from "../../Pages/Portfolio/FirstYear2021";

import { FaHome } from "react-icons/fa";


const NavbarComp = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };



  
  return (
    <>
      <Router>
        <Header />
        {["md"].map((expand) => (
          <Navbar key={expand} expand={expand} className="pt-0 pb-0 sticky-top">
            <Container fluid className="bg-white px-4 px-lg-5 ">
              <Navbar.Brand to="/" className="logo-text text-uppercase">Disha Dua</Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                 Menu
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1">
                    <Nav.Link
                      as={Link}
                      to="/"
                      activeclassname="active"
                      eventKey="/"
                      onClick={scrollToTop}
                    >
                      <FaHome />

                    </Nav.Link>

                    <Nav.Link
                      as={Link}
                      to="/About"
                      activeclassname="active"
                      eventKey="/About"
                      onClick={scrollToTop}
                    >
                      About
                    </Nav.Link>

                    <Nav.Link
                      as={Link}
                      to="/Portfolio/ThirdYear2023"
                      activeclassname="active"
                      eventKey="/Portfolio"
                      onClick={scrollToTop}
                    >
                      Portfolio
                    </Nav.Link>


                    <Nav.Link
                      as={Link}
                      to="/Contact"
                      activeclassname="active"
                      eventKey="/Contact"
                      onClick={scrollToTop}
                    >
                      Contact
                    </Nav.Link>

                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
        <Routes>
        <Route path="/" exact element={<Home />} ></Route>
        <Route path="/About" exact element={<About/>}></Route>
        <Route path="/Portfolio" exact element={<Portfolio/>}></Route>
        <Route path="/Portfolio/ThirdYear2023" exact element={<ThirdYear2023/>}></Route>
        <Route path="/Portfolio/SecondYear2022" exact element={<SecondYear2022/>}></Route>
        <Route path="/Portfolio/FirstYear2021" exact element={<FirstYear2021/>}></Route>
                <Route path="/Contact" exact element={<Contact/>} />

        </Routes>

          <Footer/>
            




      </Router>
    </>
  );
};

export default NavbarComp;
