import React from 'react'
import './HomeSlider.css'
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SliderOne from "../Assets/Enscape_04.jpg";
import SliderTwo from "../Assets/Enscape_05.jpg";
import SliderThree from "../Assets/Enscape_08.jpg";


const HomeSlider = () => {
  return (
    <React.Fragment>
      <Container fluid className="custom_slider px-4 px-lg-5 mb-5">
        <Row>
          <Col lg="12">
            <Carousel fade  controls={false}>
              <Carousel.Item>
                <img src={SliderOne} alt="" className="d-block w-100" />
                <Carousel.Caption>
                  <h1 className="text-white">First slide label</h1>
                 </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img src={SliderTwo} alt="" className="d-block w-100" />

                <Carousel.Caption>
                  <h1 className="text-white">Design Build Restaurants</h1>
               </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img src={SliderThree} alt="" className="d-block w-100" />

                <Carousel.Caption>
                  <h1 className="text-white">Third slide label</h1>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
      
    </React.Fragment>
  )
}

export default HomeSlider
