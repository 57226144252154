import React from 'react'
import second_year from '../Assets/SecondYearPortfolio'

import Col from "react-bootstrap/Col";
import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';




import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import PortfolioImg from '../PortfolioImg/PortfolioImg';
import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgShare from 'lightgallery/plugins/share';

const SecondYrGallery = () => {
  const onInit = () => {
    console.log('lightGallery has been initialized');
};


  return (
    <React.Fragment>
          <Col lg="12">

<LightGallery
       onInit={onInit}
     
       speed={500}
       plugins={[lgThumbnail, lgZoom, lgAutoplay, lgShare]}
       elementClassNames='list-container'
       licenseKey= "my license"
   >

   { second_year.map((portfolio, i)=>{
                    return <PortfolioImg key={i} id={portfolio.id} name={portfolio.name} image={portfolio.image} />

   })}
 
   </LightGallery>
 
  
</Col>




      
    </React.Fragment>
  )
}

export default SecondYrGallery
