import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AboutImg from "../../Components/Assets/disha-dua.png";
import AccordianTab from './AccordianTab';
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const About = () => {
  return (
      <React.Fragment>
     <Container fluid className="px-4 px-lg-5 mb-5 mb-sm-0">
     <Row className="justify-content-md-center d-flex align-items-center">

     <Col lg="12" className="">
            <div className="border-top border-bottom pt-4 pb-4 px-1">
           <div className="d-flex justify-content-between">
           <div> <h3 className="fw-500">About</h3></div>
            <div> 
            <Breadcrumb>
      <Breadcrumb.Item><Link as={Link}to="/"> <FaHome /></Link> </Breadcrumb.Item>
       <Breadcrumb.Item active>About</Breadcrumb.Item>
    </Breadcrumb>

            </div>


           </div>



            </div>



          </Col>



      <Col lg="12" className=''>
        <div className='bg-dark pt-3 pb-3'>
        <Row className="justify-content-center align-items-center">


        <Col md lg="3">
        <img src={AboutImg} alt="" className="d-block w-100 img-fluid " />
        </Col>

        <Col md lg="6" className=''>
        <div className='px-2 px-lg-4 text-white'>
        <h1>About</h1>
       <div className='quote'>
       <h5 className='ms-5 px-5'>interior designer</h5>
        </div> 
              <p className='mt-lg-4 mt-sm-2'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur modi recusandae dolorum consequuntur explicabo. Nobis dolores laboriosam, culpa quae, magnam qui a expedita cumque unde omnis incidunt debitis perspiciatis eligendi id. Aliquid aperiam, tempore laborum eius explicabo totam voluptatibus enim maiores soluta ullam ipsa animi quidem aspernatur adipisci sed reiciendis perferendis!
            </p>
        </div>

        </Col>



        </Row>





        </div>
      </Col>




       </Row>


  <Row className="justify-content-center align-items-center">
  <Col lg="9" className='text-center py-4'>
    <AccordianTab/>

  </Col>
  </Row>






      </Container>
     </React.Fragment>
  )

}

export default About
