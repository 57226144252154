import './App.css';
import NavabarComp from './Components/Navbarcomp/NavbarComp'


function App() {
  return (
    <>
    <NavabarComp/>

    </>

 );
}

export default App;
